import {
  FormRow,
  Grid,
  GridCol,
  Heading,
  Link,
  MainWrapper,
  PageContainer,
  Text,
} from "@flixbus/honeycomb-react";
import React from "react";
import {
  UserMatch,
  UserType,
  useFetchMatches,
  useMatchaStore,
} from "../../features/state-management/matcha-state";

import { APP_PATHS } from "../../constants";
import MatchCard from "../../features/match-card/MatchCard";
import { NavLink } from "react-router-dom";
import styles from "./Matches.module.scss";

type MatchesProp = {
  type: UserType;
};

const Matches: React.FC<MatchesProp> = ({ type }) => {
  const userProfile = useMatchaStore((state) => state.userProfile);

  // If we are on the mentees page we search for matches where
  // the user is a mentor, and vice versa.
  const userRoleToSearchBy = (type === "mentee") ? "mentor" : "mentee";
  const matchData = useFetchMatches(userProfile?.id, userRoleToSearchBy);

  const join_link = userRoleToSearchBy === "mentee" ? APP_PATHS.MENTEE_FORM : APP_PATHS.MENTOR_FORM;

  return (
    <MainWrapper full extraClasses={styles.backgroudColor}>
      <PageContainer>
        <Grid>
          <GridCol size={12} extraClasses={styles.headingContainer}>
            <Heading size={1}>{`My ${type}(s)`}</Heading>
          </GridCol>
          {matchData.length > 0 ? (
            <GridCol>
              {matchData.map((match: UserMatch, index) => (
                <FormRow key={`row-match-${index}`}>
                  <MatchCard match={match} />
                </FormRow>
              ))}
            </GridCol>
          ) : (
            <GridCol>
              <Text>
                Looks like you have no matches yet, if you already have{" "}
                <Link RouterLink={NavLink} href={join_link}>
                  joined as a {userRoleToSearchBy}
                </Link>{" "}
                you will receive an email when we find a good match for you.
              </Text>
            </GridCol>
          )}
        </Grid>
      </PageContainer>
    </MainWrapper>
  );
};

export default Matches;
