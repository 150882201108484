import {
  FORM_CONTENT_MENTEE,
  FORM_CONTENT_MENTOR,
  FormType,
} from "../../pages/form/answers.content";
import { useEffect, useState } from "react";

import { UserProfile } from "../../pages/authentication/Login";
import { create } from "zustand";
import { useGet } from "./api-auth";

export type UserType = "mentor" | "mentee";

export interface UserMatch {
  matchId: string;
  name: string;
  jobTitle: string;
  officeLocation: string;
  status: string;
  createdAt: Date;
  updatedAt: Date | undefined;
  updatedBy: string | undefined;
}

export interface UserMatches {
  mentor: UserMatch[];
  mentee: UserMatch[];
}

export interface MatchaStore {
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile) => void;
  removeUserProfile: () => void;
}

export const useMatchaStore = create<MatchaStore>((set) => ({
  userProfile: null,
  setUserProfile: (profile: UserProfile) =>
    set(() => ({ userProfile: profile })),
  removeUserProfile: () => set({ userProfile: null }),
}));

export function useFormSubmissions(
  userId: string | undefined,
  formType: FormType
) {
  const [formSubmissions, setFormSubmissions] = useState<
    Map<string, string | Array<string>>
  >(new Map());
  const fieldIdToName: Map<string, string> = mapFieldIdsToNames();

  const fetchFormSubissionsFn = useGet(
    `/u/${userId}/form-submissions`,
    transformResponse
  );

  function transformResponse(payload: any) {
    const mapForForm = new Map<string, string | Array<string>>();
    payload.forEach((field: any) => {
      if (field.value.startsWith("{")) {
        field.value = parseCurlyArray(field.value);
      }
      const fieldName = fieldIdToName.get(field.field_id);
      if (field.form_id === formType && fieldName !== undefined) {
        mapForForm.set(fieldName, field.value);
      }
    });
    return mapForForm;
  }

  function mapFieldIdsToNames() {
    const map = new Map<string, string>();
    if (formType === FormType.MENTOR) {
      for (const field of FORM_CONTENT_MENTOR) {
        map.set(field.fieldId, field.name);
      }
    }
    if (formType === FormType.MENTEE) {
      for (const field of FORM_CONTENT_MENTEE) {
        map.set(field.fieldId, field.name);
      }
    }
    return map;
  }

  function parseCurlyArray(value: string): Array<string> {
    return value.replace("{", "").replace("}", "").split(",");
  }

  async function fetchFormSubmissions() {
    try {
      const subs = await fetchFormSubissionsFn();
      setFormSubmissions(subs);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (userId) {
      fetchFormSubmissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return formSubmissions;
}

export interface UserMatchesStore {
  matches: UserMatches;
  setUserMatches: (userType: UserType, userMatches: UserMatch[]) => void;
  updateUserMatches: (
    matchId: string,
    newStatus: string,
    updatedAt: Date,
    updatedBy: string
  ) => void;
}

export const useUserMatchesStore = create<UserMatchesStore>((set) => ({
  matches: {
    mentee: [],
    mentor: [],
  },
  setUserMatches: (userType: UserType, matches: UserMatch[]) =>
    set((state) => ({
      matches: {
        mentee: userType === "mentee" ? matches : state.matches["mentee"],
        mentor: userType === "mentor" ? matches : state.matches["mentor"],
      },
    })),
  updateUserMatches: (
    matchId: string,
    newStatus: string,
    updatedAt: Date,
    updatedBy: string
  ) =>
    set((state) => ({
      matches: {
        mentee: state.matches["mentee"].map((match) => {
          if (match.matchId === matchId) {
            match.status = newStatus;
            match.updatedAt = updatedAt;
            match.updatedBy = updatedBy;
          }
          return match;
        }),
        mentor: state.matches["mentor"].map((match) => {
          if (match.matchId === matchId) {
            match.status = newStatus;
            match.updatedAt = updatedAt;
            match.updatedBy = updatedBy;
          }
          return match;
        }),
      },
    })),
}));

export function useFetchMatches(
  userId: string | undefined,
  userType: UserType
) {
  const [matches, setMatches] = useState<UserMatch[]>([]);
  const fetchMatchesFn = useGet(
    `/u/${userId}/${userType}-matches`,
    transformResponse
  );

  function transformResponse(payload: unknown) {
    const parsedPayload: UserMatch[] = (payload as Array<any>).map(
      (match: any) => ({
        matchId: match.match_id,
        name: match.display_name,
        jobTitle: match.job_title,
        officeLocation: match.office_location,
        status: match.status,
        createdAt: new Date(Number(match.created_at) * 1000),
        updatedAt: match.updated_at
          ? new Date(Number(match.updated_at) * 1000)
          : undefined,
        updatedBy: match.updated_by,
      })
    );
    return parsedPayload as unknown;
  }

  async function fetchMatches() {
    try {
      const parsedPayload = await fetchMatchesFn();
      setMatches(parsedPayload);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (userId) {
      fetchMatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userType]);

  return matches;
}

export type AdminMatch = {
  matchId: string;
  mentorName: string;
  menteeName: string;
  status: string;
  createdAt: Date;
  updatedAt: Date | undefined;
  updatedBy: string | undefined;
};

export function useFetchAdminMatches(userId: string | undefined) {
  const [adminMatches, setAdminMatches] = useState<AdminMatch[]>([]);
  const fetchAdminMatchesFn = useGet(
    `/u/${userId}/admin-matches`,
    transformPayload
  );

  function transformPayload(payload: any) {
    const parsedPayload: AdminMatch[] = payload.map((admin_match: any) => ({
      matchId: admin_match.match_id,
      mentorName: admin_match.mentor_name,
      menteeName: admin_match.mentee_name,
      status: admin_match.status,
      createdAt: new Date(Number(admin_match.created_at) * 1000),
      updatedAt: admin_match.updated_at
        ? new Date(Number(admin_match.updated_at) * 1000)
        : undefined,
      updatedBy: admin_match.updated_by,
    }));
    return parsedPayload;
  }

  async function fetchMatches() {
    try {
      const parsedPayload = await fetchAdminMatchesFn();
      setAdminMatches(parsedPayload);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (userId) {
      fetchMatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return adminMatches;
}
