import {
  Button,
  Dropdown,
  Heading,
  Popup,
  PopupSection,
} from "@flixbus/honeycomb-react";
import {
  Icon,
  IconArrowDownL,
  IconArrowUpL,
  IconAttention,
} from "@flixbus/honeycomb-icons-react";
import {
  useMatchaStore,
  useUserMatchesStore,
} from "../state-management/matcha-state";

import React from "react";

type StatusButtonProps = { matchId: string; status: string };

const StatusButton: React.FC<StatusButtonProps> = ({ matchId, status }) => {
  const [popupActive, setPopupActive] = React.useState(false);

  const [newStatus, setNewStatus] = React.useState("");

  let linkArray: any[] = [];
  let statusButtonAppearence: "primary" | "secondary" | "tertiary" | "danger" =
    "tertiary";
  switch (status) {
    case "Pending":
      statusButtonAppearence = "primary";
      linkArray = [];
      break;
    case "Waiting to meet":
      statusButtonAppearence = "primary";
      linkArray = [
        {
          text: "Start mentorship",
          href: "#",
          onClick: () => {
            setNewStatus("Ongoing");
            setPopupActive(true);
          },
        },
        {
          text: "Reject mentorship",
          href: "#",
          onClick: () => {
            setNewStatus("Rejected");
            setPopupActive(true);
          },
        },
      ];
      break;
    case "Ongoing":
      statusButtonAppearence = "secondary";
      linkArray = [
        {
          text: "Reject mentorship",
          href: "#",
          onClick: () => {
            setNewStatus("Rejected");
            setPopupActive(true);
          },
        },
        {
          text: "Finalize mentorship",
          href: "#",
          onClick: () => {
            setNewStatus("Finalized");
            setPopupActive(true);
          },
        },
      ];
      break;
    case "Finalized":
      statusButtonAppearence = "tertiary";
      linkArray = [
        {
          text: "Resume mertorship",
          href: "#",
          onClick: () => {
            setNewStatus("Waiting to meet");
            setPopupActive(true);
          },
        },
      ];
      break;
    case "Rejected":
      statusButtonAppearence = "danger";
      linkArray = [
        {
          text: "Resume mertorship",
          href: "#",
          onClick: () => {
            setNewStatus("Waiting to meet");
            setPopupActive(true);
          },
        },
      ];
      break;
    default:
      linkArray = [];
  }

  const [arrowUp, setArrowUp] = React.useState(false);

  return (
    <React.Fragment>
      <Dropdown
        links={linkArray}
        onToggle={(isActive) => setArrowUp(isActive)}
        smartPosition
      >
        <Button appearance={statusButtonAppearence}>
          {status} <Icon InlineIcon={arrowUp ? IconArrowUpL : IconArrowDownL} />
        </Button>
      </Dropdown>
      <StatusChangePopup
        matchId={matchId}
        newStatus={newStatus}
        popupActive={popupActive}
        setPopupActive={setPopupActive}
      />
    </React.Fragment>
  );
};

type StatusChangePopupProp = {
  matchId: string;
  newStatus: string;
  popupActive: boolean;
  setPopupActive: (active: boolean) => void;
};

const StatusChangePopup: React.FC<StatusChangePopupProp> = ({
  matchId,
  newStatus,
  popupActive,
  setPopupActive,
}) => {
  const targetRef = React.useRef(null);

  const updateUserMatches = useUserMatchesStore(
    // TODO: refactor when changing match from admin view
    (state) => state.updateUserMatches
  );

  const userProfile = useMatchaStore((state) => state.userProfile);
  async function updatePutMatch(matchId: string, match_status: string) {
    if (!userProfile) {
      console.error("userProfile is null");
      return;
    }

    try {
      const userUuid = userProfile.id;
      const response = await fetch(
        `/u/${userUuid}/matches/${matchId}/update-status`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            new_status: match_status,
          }),
        }
      );
      if (response.ok) {
        const payload = await response.json();
        console.log(payload);
        updateUserMatches(
          matchId,
          payload.status,
          new Date(Number(payload.updated_at) * 1000),
          payload.updated_by
        );
      }
      throw new Error("Network response was not ok.");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Popup
      id="status-change-popup"
      aria-labelledby="accessible-popup-title"
      aria-describedby="accessible-popup-content"
      active={popupActive}
      onOverlayClick={() => setPopupActive(false)}
      targetRef={targetRef}
    >
      <PopupSection type="icon" extraClasses="my-class">
        <Icon InlineIcon={IconAttention} size={12} />
      </PopupSection>
      <PopupSection type="title">
        <Heading id="accessible-popup-title" size={2}>
          Mentorship status change
        </Heading>
      </PopupSection>
      <PopupSection type="content" id="accessible-popup-content">
        {/* TODO: add custom text for each transition */}
        {`You are about to change the mentorship status to '${newStatus}'`}
      </PopupSection>
      <PopupSection type="actions">
        <Button appearance="tertiary" onClick={() => setPopupActive(false)}>
          Cancel
        </Button>
        <Button
          appearance="secondary"
          onClick={() => {
            setPopupActive(false);
            updatePutMatch(matchId, newStatus);
          }}
        >
          Confirm
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default StatusButton;
