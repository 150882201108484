import {
  Notification,
  NotificationComp,
  NotificationContainer,
} from "@flixbus/honeycomb-react";

import React from "react";

export interface NotificationCtx {
  pushNotification: (
    message: string,
    appearance: NotificationAppearance
  ) => void;
}

export enum NotificationAppearance {
  "success" = "success",
  "danger" = "danger",
  "warning" = "warning",
}

const NotificationContext = React.createContext<NotificationCtx | null>(null);

export function NotificationCenterCtx(props: { children: React.ReactNode }) {
  const [notifications, setNotifications] = React.useState<
    Array<NotificationComp>
  >([]);

  function pushNotification(
    message: string,
    appearance: NotificationAppearance
  ) {
    setNotifications([
      <Notification
        toast
        appearance={appearance}
        dismissCallback={() => setNotifications([])}
      >
        {message}
      </Notification>,
    ]);
  }

  return (
    <NotificationContext.Provider value={{ pushNotification }}>
      <NotificationContainer>{notifications}</NotificationContainer>
      {props.children}
    </NotificationContext.Provider>
  );
}

export class OutsideContextError extends Error {}

export function useNotificationCenter() {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new OutsideContextError(
      "useNotificationCenter must be used within a NotificationsContextProvider"
    );
  }
  return context as NotificationCtx;
}
