import {
  BackendError,
  CustomError,
  InputError,
  SearchError,
  UserInputError,
} from "./custom-errors";

import React from "react";
import { useMsal } from "@azure/msal-react";

export interface ApiConfig {
  azureAdApiId: string;
  matchaApiUrl: string;
  blankRedirectUri: string;
}

const ApiConfigContext = React.createContext<ApiConfig | null>(null);

export function ApiContextProvider(props: {
  apiConfig: ApiConfig;
  children: React.ReactNode;
}) {
  if (!props.apiConfig) {
    return null;
  }
  return (
    <ApiConfigContext.Provider value={props.apiConfig}>
      {props.children}
    </ApiConfigContext.Provider>
  );
}

export class OutsideContextError extends Error {}

export function useApiConfig() {
  const context = React.useContext(ApiConfigContext);
  if (!context) {
    throw new OutsideContextError(
      "useApiConfig must be used within a ApiContextProvider"
    );
  }
  return context as ApiConfig;
}

function useAuthToken() {
  const { instance, accounts } = useMsal();
  const apiConfig = useApiConfig();
  const matchaApiRequestScope = "Matcha.All";

  return async () => {
    await instance.initialize();
    const completeRequestScope = `api://${apiConfig.azureAdApiId}/${matchaApiRequestScope}`;

    const authResult = await instance.acquireTokenSilent({
      account: accounts[0],
      scopes: [completeRequestScope],
      redirectUri: apiConfig.blankRedirectUri,
    });

    return authResult.accessToken;
  };
}

function buildAuthFetch(
  queryUrl: string,
  getAccessToken: () => Promise<string>,
  body?: object,
  method?: "PUT" | "POST"
) {
  return async (): Promise<Response> => {
    const accessToken = await getAccessToken();
    const authHeader = `Bearer ${accessToken}`;
    const headers = new Headers();
    headers.append("Authorization", authHeader);
    headers.append("Content-Type", "application/json");
    const options: RequestInit = {
      method: "GET",
      headers: headers,
    };
    if (body) {
      options.method = method ? method : "POST";
      options.body = JSON.stringify(body);
    }
    return fetch(queryUrl, options);
  };
}

/**
 * @param urlPath - endpoint for your GET request
 * @param transformFn - Additional data mangling after json is parsed
 * @returns A fetch function that parses a JSON response and optionally transformed it
 */
export function useGet(
  urlPath: string,
  transformFn?: (param: unknown) => unknown
) {
  const apiConfig = useApiConfig();
  const getTokenFunc = useAuthToken();
  function parseAndTransform(res: Response) {
    if (res.ok) {
      return res
        .json()
        .then((data) => (transformFn ? transformFn(data) : data));
    } else {
      switch (res.status) {
        case 422:
          return Promise.reject(new UserInputError());
        case 404:
          return Promise.reject(new SearchError());
        case 500:
          return Promise.reject(new BackendError());
        case 400:
          return Promise.reject(new InputError());
        default:
          // These are unexpected responses - as in out of the api-contract defined above.
          return res
            .text()
            .then((respText) =>
              Promise.reject(new CustomError(respText, res.status))
            );
      }
    }
  }
  const url = `${apiConfig.matchaApiUrl}${urlPath}`;
  const fetchFn = buildAuthFetch(url, getTokenFunc);
  return async () => fetchFn().then(parseAndTransform);
}

export function usePost() {
  const getTokenFunc = useAuthToken();
  const apiConfig = useApiConfig();
  const postRequestFn = (urlPath: string, body: any) => {
    const requestUrl = `${apiConfig.matchaApiUrl}${urlPath}`;
    return buildAuthFetch(requestUrl, getTokenFunc, body)();
  };
  return postRequestFn;
}

/**
 *
 * @returns async function that
 */
export function usePut() {
  const getTokenFunc = useAuthToken();
  const apiConfig = useApiConfig();
  const putRequestFn = (urlPath: string, body: any) => {
    const requestUrl = `${apiConfig.matchaApiUrl}${urlPath}`;
    return buildAuthFetch(requestUrl, getTokenFunc, body, "PUT")();
  };
  return putRequestFn;
}
