import { Box, Grid, GridCol, Heading, Text } from "@flixbus/honeycomb-react";

import React from "react";
import StatusButton from "../status-button/StatusButton";
import { UserMatch } from "../state-management/matcha-state";
import styles from "./MatchCard.module.scss";

type MatchCardProp = {
  match: UserMatch;
};

const MatchCard: React.FC<MatchCardProp> = ({ match }) => {
  return (
    <Box>
      <Grid>
        <GridCol size={12}>
          <Grid>
            <GridCol size={10} extraClasses={styles.matchCardTitleContainer}>
              <Heading size={3} flushSpace>
                {match.name}
              </Heading>
              <Text>
                {match.jobTitle} • {match.officeLocation}
              </Text>
            </GridCol>
            <GridCol size={2}>
              <StatusButton matchId={match.matchId} status={match.status} />
            </GridCol>
          </Grid>
        </GridCol>
        <GridCol>
          <Grid>
            <GridCol size={2}>
              <Text Elem="div" small>
                <b className={styles.matchCardHeading}>Match date:</b>{" "}
                {match.createdAt.toLocaleDateString()}
              </Text>
            </GridCol>
            <GridCol size={2}>
              <Text Elem="div" small>
                <b className={styles.matchCardHeading}>Update date:</b>{" "}
                {match.updatedAt ? match.updatedAt.toLocaleDateString() : "--"}
              </Text>
            </GridCol>
            <GridCol>
              <Text Elem="div" small>
                <b className={styles.matchCardHeading}>Updated by:</b>{" "}
                {match.updatedBy ? match.updatedBy : "--"}
              </Text>
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </Box>
  );
};

export default MatchCard;
