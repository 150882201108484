import {
  Configuration,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import {useApiConfig} from "./api-auth";
import {MsalProvider, useMsal} from "@azure/msal-react";
import {useEffect, useMemo} from "react";

import React from "react";
import {Spinner} from "@flixbus/honeycomb-react";

export interface BackgroundAuthProps {
  children: React.ReactNode;
  msalConfig: Configuration;
  onError: (error: Error | unknown) => void;
}

export function BackgroundAuth(props: BackgroundAuthProps) {
  const {blankRedirectUri} = useApiConfig();
  const authenticationApiScope = "User.Read";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const msalInstance = useMemo(
    () => new PublicClientApplication(props.msalConfig),
    [props.msalConfig]
  );

  useEffect(() => {
    async function loginSilently() {
      try {
        // This check is not absolutely correct, but makes sure to execute sso only once.
        const isSignedOut = msalInstance.getAllAccounts().length === 0;
        await msalInstance.initialize();
        if (isSignedOut) {
          // This is only meant to automatically refresh access tokens.
          await msalInstance.ssoSilent({
            scopes: [authenticationApiScope],
            redirectUri: blankRedirectUri,
          });
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // This is expected when the user has no active AAD session.
          // Users will be redirected to a login page from within the app.
          return;
        } else {
          props.onError(error);
        }
      }
    }

    loginSilently();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <MsalProvider instance={msalInstance}>
    <AuthProgress>{props.children}</AuthProgress>
  </MsalProvider>
  );
}

export function AuthProgress(props: {
  children: React.ReactNode;
}): JSX.Element {
  const {inProgress} = useMsal();

  if (inProgress !== InteractionStatus.None || !props.children) {
    return (
    <div>
      <Spinner size="sm"/>
      <span>Loading...</span>
    </div>
    );
  }
  return props.children as JSX.Element;
}
