export const APP_PATHS = {
  HOME: "/",
  MENTEE_FORM: "/forms/mentee",
  MENTOR_FORM: "/forms/mentor",
  MENTEE_MATCHES: "/matches/mentee",
  MENTOR_MATCHES: "/matches/mentor",
  ADMIN_VIEW: "/admin",
  LOGIN: "/login",
  LOGOUT: "/logout",
};

// TODO: list should be a secret!
export const ADMIN_USER_IDS = [
  "421cc79a-3332-493d-83eb-65600b4f8697", //Alex
  "65c00416-20a6-4267-bef5-7584ec6d327d", //Flavia
  "bdef5980-4c2e-4e98-82b7-cba1baf6b41a", //Tobi
  "218b4b54-20ad-407f-8b33-921d20d5bff4", //Alberto
];
