import {
  Box,
  Button,
  Grid,
  GridCol,
  Heading,
  Text,
} from "@flixbus/honeycomb-react";
import {
  useApiConfig,
} from "../../features/state-management/api-auth";
import { Navigate, useSearchParams } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { IPublicClientApplication } from "@azure/msal-browser";
import styles from "./Authentication.module.scss";
import { useEffect } from "react";

export interface UserProfile {
  displayName: string;
  mail: string;
  preferredLanguage: string;
  jobTitle: string;
  id: string;
  officeLocation: string;
}

export const msGraphApiEndpoint = "https://graph.microsoft.com/v1.0/me";

interface LoginProps {
  setUserProfile: (profile: UserProfile) => void;
  userProfile: UserProfile | null;
}

export function Login({ setUserProfile, userProfile }: LoginProps) {
  const apiConfig = useApiConfig();
  const authenticationApiScope = "User.Read";
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();

  function login() {
    instance
      .loginRedirect({
        scopes: [authenticationApiScope],
      })
      .then(() => {
        instance.setActiveAccount(instance.getAllAccounts()[0]);
      })
      .catch(console.error);
  }

  useEffect(() => {
    if (isAuthenticated) {
      requestProfile(instance, apiConfig.blankRedirectUri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, requestProfile]);

  if (userProfile) {
    return <Navigate to={searchParams.get("goTo") || "/"} replace />;
  }

  return (
    <Grid>
      <GridCol size={6} push={3}>
        <Box extraClasses={styles.loginBox}>
          <Heading size={3} sectionHeader>
            Welcome to Matcha
          </Heading>
          <Text>
            You need to login in order to use this application. Please click the
            button below to open the authentication dialog.
          </Text>
          <Button appearance="tertiary" onClick={login}>
            Login
          </Button>
        </Box>
      </GridCol>
    </Grid>
  );
}

export async function requestProfile(
  msalInstance: IPublicClientApplication,
  blankRedirectUri: string
) {
  const currentAccount = msalInstance.getAllAccounts()[0];
  const authenticationApiScope = "User.Read";

  if (!currentAccount) {
    throw new Error(
      "No active account found while trying to request profile data"
    );
  }
  const authResult = await msalInstance.acquireTokenSilent({
    account: currentAccount,
    scopes: [authenticationApiScope],
    redirectUri: blankRedirectUri,
  });

  return await callMsGraph(authResult.accessToken);
}

export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(msGraphApiEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}
