import {
  Box,
  Button,
  Grid,
  GridCol,
  Heading,
  MainWrapper,
  PageContainer,
  Text,
} from "@flixbus/honeycomb-react";

import { APP_PATHS } from "../../constants";
import { Link } from "react-router-dom";
import React from "react";
import styles from "./Home.module.scss";

const Home = () => (
  <>
    <MainWrapper extraClasses={styles.backgroundImage}>
      <PageContainer>
        <Grid gutterSize="4">
          <GridCol size={12} extraClasses={styles.headingContainer}>
            <Heading size={1} flushSpace extraClasses={styles.mainHeading}>
              Mentoring @ Flix 🍵
            </Heading>
          </GridCol>
          <GridCol size={12} extraClasses={styles.quoteContaioner}>
            <Box highlighted>
              <Text Elem="div">
                <blockquote>
                  Mentoring is a reciprocal and collaborative at-will
                  relationship that most often occurs between a senior and
                  junior employee for the purpose of the mentee’s growth,
                  learning, and career development.
                </blockquote>
              </Text>
              <Text small>Association of Talent Development</Text>
            </Box>
          </GridCol>
        </Grid>
      </PageContainer>
    </MainWrapper>
    <MainWrapper full>
      <PageContainer>
        <Grid gutterSize="4" extraClasses={styles.textContainer}>
          <GridCol size={6}>
            <Text Elem="div">
              <h2>Expectations towards mentees</h2>
              <h3>Motivation</h3>
              <ul>
                <li>
                  Desire to take responsibility for their own growth and
                  development
                </li>
              </ul>
              <h3>Openness</h3>
              <ul>
                <li>
                  Willingness to share their goals, challenges, and experiences
                </li>
                <li>Open-mindedness to consider new perspectives and ideas</li>
              </ul>
              <h3>Mindset</h3>
              <ul>
                <li>
                  Commitment to follow through on action items and goals set
                  with the mentors
                </li>
                <li>
                  Willingness to invest time and effort in building a meaningful
                  mentoring relationship
                </li>
              </ul>
            </Text>
          </GridCol>
          <GridCol size={6}>
            <Text Elem="div">
              <h2>Expectations towards mentors</h2>
              <h3>Motivation</h3>
              <ul>
                <li>Passion for helping others grow and achieve their goals</li>
                <li>
                  Willingness to invest time and effort in building a meaningful
                  mentor-mentee relationship
                </li>
                <li>
                  Desire to contribute to the success of the organization and
                  its employees
                </li>
              </ul>
              <h3>Experience</h3>
              <ul>
                <li>
                  Readiness to offer their perspective and provide feedback to
                  the mentee
                </li>
                <li>Willingness to share their own set-backs and challenges</li>
              </ul>
              <h3>Mindset</h3>
              <ul>
                <li>
                  Willingness to learn from the mentee and embrace new
                  perspectives
                </li>
                <li>
                  Growth-oriented mindset that values continuous learning and
                  development
                </li>
              </ul>
            </Text>
          </GridCol>
          <GridCol size={4} push={1}>
            <Link
              to={`${APP_PATHS.MENTEE_FORM}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                appearance="primary"
                display="block"
                extraClasses={styles.joinButton}
              >
                Join as a mentee!
              </Button>
            </Link>
          </GridCol>
          <GridCol size={4} push={2}>
            <Link
              to={`${APP_PATHS.MENTOR_FORM}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                appearance="primary"
                display="block"
                extraClasses={styles.joinButton}
              >
                Join as a mentor!
              </Button>
            </Link>
          </GridCol>
        </Grid>
      </PageContainer>
    </MainWrapper>
  </>
);

export default Home;
