import {
  AdminMatch,
  useFetchAdminMatches,
  useMatchaStore,
} from "../../features/state-management/matcha-state";
import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableSorter,
  Grid,
  GridCol,
  Heading,
  MainWrapper,
  PageContainer,
  Tag,
} from "@flixbus/honeycomb-react";
import {
  Icon,
  IconCheckmarkStrong,
  IconCrossed,
} from "@flixbus/honeycomb-icons-react";

import React from "react";
import styles from "./AdminView.module.scss";

const AdminView: React.FC = () => {
  const userProfile = useMatchaStore((state) => state.userProfile);
  const matchData = useFetchAdminMatches(userProfile?.id);

  const [sortedData, setSortedData] = React.useState<AdminMatch[]>([]);
  React.useEffect(() => setSortedData(matchData), [matchData, setSortedData]);

  const sortData = (sorting: any, comparator: any) => {
    const newlySortedData = [...sortedData].sort(comparator);

    if (sorting === "descending") {
      setSortedData(newlySortedData.reverse());
      return;
    }

    setSortedData(newlySortedData);
  };

  const sortByCreatedAt = (sorting: any) => {
    sortData(sorting, (a: any, b: any) => {
      const aName = new Date(a.created_at);
      const bName = new Date(b.created_at);

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  };

  const sortByUpdatedAt = (sorting: any) => {
    sortData(sorting, (a: any, b: any) => {
      const aName = new Date(a.updated_at);
      const bName = new Date(b.updated_at);

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  };

  function getStatusTagAppearence(status: string) {
    let statusTagApparence: undefined | "success" | "warning" | "danger" =
      undefined;
    switch (status) {
      case "Pending":
        statusTagApparence = "danger";
        break;
      case "Waiting to meet":
        statusTagApparence = "success";
        break;
      case "Ongoing":
        statusTagApparence = "success";
        break;
      case "Finalized":
        statusTagApparence = "success";
        break;
      case "Rejected":
        statusTagApparence = "warning";
        break;
    }
    return statusTagApparence;
  }

  return (
    <MainWrapper full extraClasses={styles.backgroudColor}>
      <PageContainer>
        <Grid>
          <GridCol size={12} extraClasses={styles.headingContainer}>
            <Heading size={1}>{"Admin View"}</Heading>
          </GridCol>
          <GridCol>
            <DataTable
              scrollable="sticky-header"
              height="750px" // TODO: choose size based on screen
              headers={
                <DataTableHead>
                  <th scope="col">Mentor</th>
                  <th scope="col">Mentee</th>
                  <DataTableSorter onSortingChange={sortByCreatedAt}>
                    Created At
                  </DataTableSorter>
                  <DataTableSorter onSortingChange={sortByUpdatedAt}>
                    Updated At
                  </DataTableSorter>
                  <th scope="col">Updated By</th>
                  <th scope="col">Status</th>
                  <th scope="col" />
                </DataTableHead>
              }
            >
              {sortedData.map(
                ({
                  matchId,
                  mentorName,
                  menteeName,
                  status,
                  createdAt,
                  updatedAt,
                  updatedBy,
                }) => (
                  <DataTableRow key={matchId}>
                    <td>{mentorName}</td>
                    <td>{menteeName}</td>
                    <td>{createdAt.toLocaleDateString()}</td>
                    <td>{updatedAt ? updatedAt.toLocaleDateString() : "-"}</td>
                    <td>{updatedBy ? updatedBy : "-"}</td>
                    <td>
                      {
                        <Tag
                          display="outlined"
                          appearance={getStatusTagAppearence(status)}
                        >
                          {status}
                        </Tag>
                      }
                    </td>
                    <td>
                      {status === "Pending" && (
                        <ButtonGroup orientation="horizontal">
                          <Button display="square" aria-label="Accept match">
                            <Icon
                              InlineIcon={IconCheckmarkStrong}
                              aria-hidden="true"
                            />
                          </Button>
                          <Button display="square" aria-label="Reject match">
                            <Icon InlineIcon={IconCrossed} aria-hidden="true" />
                          </Button>
                        </ButtonGroup>
                      )}
                    </td>
                  </DataTableRow>
                )
              )}
            </DataTable>
          </GridCol>
        </Grid>
      </PageContainer>
    </MainWrapper>
  );
};

export default AdminView;
