// Add fonts used by Honeycomb
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import { ADMIN_USER_IDS, APP_PATHS } from "./constants";
import {
  BrowserRouter,
  Navigate,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import {
  Login,
  UserProfile,
  requestProfile,
} from "./pages/authentication/Login";
import { ThemeWrapper, themeDefault } from "@flixbus/honeycomb-react";
import { apiConfig, msalConfig } from "./features/state-management/app-config";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useMemo } from "react";

import AdminView from "./pages/admin/AdminView";
import { ApiContextProvider } from "./features/state-management/api-auth";
import { BackgroundAuth } from "./features/state-management/background-auth";
import Header from "./features/header/Header";
import Home from "./pages/home/Home";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Logout } from "./pages/authentication/Logout";
import Matches from "./pages/matches/Matches";
import MenteeForm from "./pages/form/mentee/MenteeForm";
import MentorForm from "./pages/form/mentor/MentorForm";
import { NotificationCenterCtx } from "./features/notification-center/notification-center";
import { useMatchaStore } from "./features/state-management/matcha-state";

const AppRoutes = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { setUserProfile, userProfile } = useMatchaStore();
  const { instance } = useMsal();

  function loadProfile() {
    async function setProfileWithMSData() {
      try {
        const profile = await requestProfile(
          instance,
          apiConfig.blankRedirectUri
        );
        setUserProfile(profile);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          navigate("/login");
        } else {
          console.error("Error while fetching profile data", error);
        }
      }
    }
    if (isAuthenticated && !userProfile) {
      setProfileWithMSData();
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadProfile, [isAuthenticated]);

  const routes = useMemo(() => {
    interface ProtectedRouteProps {
      userProfile: UserProfile | null;
      adminRoute?: boolean;
      children: JSX.Element;
    }

    function ProtectedRoute({
      children,
      adminRoute = false,
    }: ProtectedRouteProps): JSX.Element {
      const isAuthenticated = useIsAuthenticated();
      const account = useAccount();
      if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
      }

      if (adminRoute)
        if (
          account?.localAccountId &&
          ADMIN_USER_IDS.includes(account.localAccountId)
        ) {
          return children;
        } else {
          return <Navigate to="/" replace />;
        }

      return children;
    }

    return [
      {
        path: APP_PATHS.LOGIN,
        element: (
          <Login setUserProfile={setUserProfile} userProfile={userProfile} />
        ),
      },
      { path: APP_PATHS.LOGOUT, element: <Logout /> },
      { path: APP_PATHS.HOME, element: <Home /> },
      {
        path: APP_PATHS.MENTEE_FORM,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <MenteeForm />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTOR_FORM,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <MentorForm />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTEE_MATCHES,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <Matches type="mentee" />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTOR_MATCHES,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <Matches type="mentor" />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.ADMIN_VIEW,
        element: (
          <ProtectedRoute userProfile={userProfile} adminRoute={true}>
            <AdminView />
          </ProtectedRoute>
        ),
      },
      // { path: '*', element: <NotFound /> },
    ];
  }, [setUserProfile, userProfile]);

  return useRoutes(routes);
};

function App() {
  return (
    <ApiContextProvider apiConfig={apiConfig}>
      <BackgroundAuth msalConfig={msalConfig} onError={console.error}>
        <BrowserRouter>
          <ThemeWrapper theme="default" themes={{ default: themeDefault }}>
            <NotificationCenterCtx>
              <Header />
              <AppRoutes />
            </NotificationCenterCtx>
          </ThemeWrapper>
        </BrowserRouter>
      </BackgroundAuth>
    </ApiContextProvider>
  );
}

export default App;
